import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CollegeRef, Email, RcrtEventRef, RcrtShare, User as RcrtUser} from '@nxt/model-rcrt';

import {CommonModule} from '@angular/common';

import {RcrtDataService} from '../_services/rcrt-data.service';
import {IconsComponent} from '@library/shared/icons/icons.component';

@Component({
    selector: 'rcrt-results-metrics',
    standalone: true,
    imports: [
        CommonModule,
        IconsComponent
    ],
    template: `
        <div class="flex w-full place-content-end {{ color }}" *ngIf="properties?.length">
            <div class="flex flex-col">
                <div *ngIf="headline" class="text-xs pr-2 italic text-right uppercase">{{ headline }}</div>
                <div class="flex space-x-3">
                    <ng-container *ngFor="let property of properties">
                        <div *ngIf="parent?._type === 'emails' && (property === 'sent' || property === 'draft' || property ==='delivered')"
                             [title]="property" 
                             class="flex flex-col p-1 place-items-center justify-between"
                        >
                            <icon name="heroicon-outline-badge-check" *ngIf="property !== 'draft'" class="h-4 w-4">{{ parent.results[property] }}</icon>
                            <icon name="heroicon-outline-pencil-alt" *ngIf="property === 'draft'" class="h-4 w-4">{{ parent.results[property] }}</icon>
                            <div class="text-xxs font-bold uppercase">{{ property }}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let property of properties">
                        <div *ngIf="property !== 'sent' && property !=='draft' && property !=='delivered' && property !== 'clicks'" [title]="property" 
                             class="flex flex-col p-1 place-items-center justify-between relative">
                            <div class="flex">
                                <div class="text-sm">{{ parent.results[property] }}</div>
                            </div>
                            <div class="text-xxs font-bold uppercase">{{ property }}</div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    `
})
export class RcrtResultsMetrics  implements OnChanges {
    @Input() headline: string;
    @Input() parent: RcrtEventRef|RcrtShare|RcrtUser|CollegeRef|Email;
    @Input() color: string = 'text-gray-500';
    properties: any[];

    constructor(
        public rdSvc: RcrtDataService
    ) {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.parent?._type==='emails') {
            if (this.parent.active) {
                this.properties = this.parent.results ? Object.keys(this.parent.results).sort() : null;
            } else {
                this.properties = ['draft'];
            }
        } else if (this.parent?.results) {
            this.properties = this.parent.results ? Object.keys(this.parent.results).sort() : null;
        }
    }

}
