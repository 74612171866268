import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {getApp, initializeApp, provideFirebaseApp} from '@angular/fire/app';

import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {PageService} from '@library/shared/_services/page.service';
import {ClientService} from '@library/shared/_services/client.service';
import {ThemeService} from '@library/shared/_services/theme.service';
import {HttpClientModule} from '@angular/common/http';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';

import {environment} from '@env/environment';
import {RcrtClientGuard} from './_services/rcrt-client.guard';
import {NotificationDialog} from '@library/shared/alert/notification.dialog';
import {getAnalytics, provideAnalytics, ScreenTrackingService} from '@angular/fire/analytics';
import {ServiceWorkerModule} from '@angular/service-worker';
import {ProgressComponent} from '@library/shared/progress/progress.component';
import {RcrtPageNotFound} from './public/404.page';
import {RcrtHeader} from './_components/header/rcrt-header';
import {RcrtFooter} from './_components/footer/rcrt-footer';
import {ConsumerFireService} from '@library/consumer/_services/consumer.fire.service';
import {RcrtStatsService} from './_services/rcrt-stats.service';

import {sharedLazyComponents} from '@library/shared.lazy.components';
import {rcrtLazyComponents} from './_components/rcrt.lazy.components';
import {FlexPage} from '@library/shared/flex/flex.page';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {RcrtDataService} from './_services/rcrt-data.service';
import {ConsumerAccountService} from '@library/consumer/_services/consumer.account.service';
import {AnalyticsService} from '@library/shared/_services/analytics.service';
import {RcrtCartService} from './_services/rcrt-cart.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule, BrowserAnimationsModule, HttpClientModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: true,
            registrationStrategy: 'registerWhenStable:6000'
        }),
        provideFirebaseApp(() => {
            return initializeApp(environment.firebase, environment.appName);
        }),
        provideAnalytics(() => {
            return getAnalytics(getApp(environment.appName))
        }),
        provideAuth(() => {
            return getAuth(getApp(environment.appName));
        }),
        RouterModule.forRoot([
            {
                path: '',
                component: FlexPage,
                canActivate: [RcrtClientGuard],
                data: { header: false, footer: false }
            },
            {
                path: 'public',
                loadChildren: () => import('./public/public.module').then(m => m.RcrtPublicModule),
                canActivate: [RcrtClientGuard]
            },
            {
                path: 'login',
                loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
                canActivate: [RcrtClientGuard]
            },
            {
                path: 'account',
                loadChildren: () => import('./account/rcrt-account.module').then(m => m.RcrtAccountModule),
                canActivate: [RcrtClientGuard]
            },
            {
                path: 'checkout',
                loadChildren: () => import('./checkout/rcrt-checkout.module').then(m => m.RcrtCheckoutModule),
                canActivate: [RcrtClientGuard]
            },
            {
                path: '404',
                component: RcrtPageNotFound,
                canActivate: [RcrtClientGuard]
            },
            {
                path: '**',
                loadChildren: () => import(`@library/shared/flex/flex.page`).then(m => m.FlexPageModule),
                canActivate: [RcrtClientGuard]
            }
        ], {scrollPositionRestoration: 'enabled'}),
        NotificationDialog, ProgressComponent, RcrtHeader, RcrtFooter
    ],
    providers: [
        ThemeService, PageService, ClientService, RcrtClientGuard, LocalStorageService,
        ConsumerFireService, ScreenTrackingService, RcrtStatsService, RcrtDataService,
        ConsumerAccountService, RcrtCartService, AnalyticsService,
        {provide: 'MenuService', useValue: {getMenu: () => {}}},
        {provide: 'UserService', useValue: {isRole: (roles) => {
            return true;
        }}},
        {provide: 'LazyComponents', useValue: [sharedLazyComponents, rcrtLazyComponents]}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
