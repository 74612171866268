import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

import {Collab, olm, RCRT_USER_TYPE, RcrtClub, RcrtClubUser, User as RcrtUser} from '@nxt/model-rcrt';
import {ConsumerFireService} from '@library/consumer/_services/consumer.fire.service';
import {PageService} from '@library/shared/_services/page.service';
import {ClientService} from '@library/shared/_services/client.service';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';

@Injectable()
export class RcrtDataService {
    user$: BehaviorSubject<RcrtUser> = new BehaviorSubject<RcrtUser>(undefined);
    club$: BehaviorSubject<RcrtClub> = new BehaviorSubject<RcrtClub>(undefined);
    cUser$: BehaviorSubject<RcrtClubUser> = new BehaviorSubject<RcrtClubUser>(undefined);
    player$: BehaviorSubject<RcrtUser> = new BehaviorSubject<RcrtUser>(undefined);
    roles$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    menu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    title$: BehaviorSubject<string> = new BehaviorSubject('');
    emailId: string;
    unread: number = 0;
    collab: Collab;
    last_check: number = 0;
    pSub: Subscription;
    cSub: Subscription;
    crSub: Subscription;
    claims: { active: boolean, club: string} = { active: null, club: ''};

    get isPlayer() {
        return (this.user$.getValue()?.id === this.player$.getValue()?.id);
    }

    constructor(
        private fSvc: ConsumerFireService,
        private pSvc: PageService,
        private cSvc: ClientService,
        private lSvc: LocalStorageService
    ) {
        this.pSvc.blocking$.next(true);
        this.user$
            .subscribe(async (user:RcrtUser) => {

                let id: string = user?.id;
                if (user?.type === RCRT_USER_TYPE.PLAYER) {
                    this.watchPlayer(user?.id, user.type);
                } else {
                    if (user?.collab) {
                        id = user.collab;
                        this.watchPlayer(user?.collab, 'collab');
                    } else {
                        this.pSub?.unsubscribe();
                        this.player$.next(null);
                    }
                }

                if (id) {

                    this.watchClub(user?.club);
                    let result = await this.cSvc.callAPI(`/rcrt/user/${id}/claims`, 'get');
                    if (result.active !== undefined) {
                        this.claims = result;
                    } else {
                        this.claims = { active: false, club: ''};
                    }
                    // for now.
                    this.claims = { active: true, club: ''};
                    console.log(this.claims);
                }
                this.pSvc.blocking$.next(false);
            });

    }

    watchPlayer(id: string, debug?: string) {
        this.pSub?.unsubscribe();
        this.pSub = this.fSvc.watchDoc(`users/${id}`, null, true)
            .subscribe(async res => {
                let player: RcrtUser = new RcrtUser( res, olm );
                if (player._exists) {
                    this.player$.next(player);
                    this.buildDiff();
                } else {
                    this.player$.next(null);
                }
                this.pSvc.blocking$.next(false);
            },
                e => {
                    this.player$.next(null);
                // console.error(e);
                },
                () => {
                    this.pSvc.blocking$.next(false);
                }
        );
    }

    watchClub(id: string) {
        this.cSub?.unsubscribe();
        if (id) {
            this.cSub = this.fSvc.watchDoc(`clubs/${id}`, null, true)
                .subscribe(async res => {
                        let club: RcrtClub = new RcrtClub( res, olm );
                        if (club._exists) {
                            this.club$.next(club);
                            this.watchRole(id);
                        } else {
                            this.club$.next(null);
                            this.watchRole('');
                        }
                        this.pSvc.blocking$.next(false);
                    },
                    e => {
                        this.club$.next(null);
                        this.watchRole('');
                        console.error(e);
                    },
                    () => {
                        this.pSvc.blocking$.next(false);
                    }
                );
        } else {
            this.club$.next(null);
        }
    }

    watchRole(id: string) {
        this.crSub?.unsubscribe();
        if (id && this.user$.getValue()?.id) {
            this.crSub = this.fSvc.watchDoc(`clubs/${id}/club_users/${this.user$.getValue().id}`, null, true)
                .subscribe(async res => {
                        let cUser: RcrtClubUser = new RcrtClubUser( res, olm );
                        if (cUser._exists) {
                            this.cUser$.next(cUser);
                        } else {
                            this.cUser$.next(null);
                        }
                        this.pSvc.blocking$.next(false);
                    },
                    e => {
                        this.cUser$.next(null);
                        console.error(2,e);
                    },
                    () => {
                        this.pSvc.blocking$.next(false);
                    }
                );
        } else {
            this.cUser$.next(null);
        }
    }

    async buildDiff() {
        let player: RcrtUser = this.player$.getValue();
        this.last_check  = !isNaN(Number(this.user$.getValue().last_check)) ? this.user$.getValue().last_check : 0;
        if (!this.isPlayer) {
            if (!this.collab) {
                this.collab = await this.cSvc.callAPI(`/collab/list/${this.player$.getValue()?.id}`, 'get');
                console.log(this.collab);
            }
            this.last_check  = !isNaN(Number(this.collab.last_check)) ? this.collab.last_check : this.last_check;
        }

        let coll = await this.fSvc.getColl(
            `users/${player.id}/activity`, [
                { name: 'orderBy', args: ['date','desc'] },
                { name: 'where', args: ['date','>', this.last_check ] },
                { name: 'where', args: ['user_id','!=', this.user$.getValue().id ] }
            ]
        ).toPromise();
        this.unread = coll?.length||0;
    }

    async markAsRead() {
        if (this.isPlayer) {
            await this.updateUser(this.user$.getValue(), { last_check: Date.now() });
        } else if (this.collab?.id) {
            try {
                this.collab = new Collab( await this.cSvc.callAPI(`/collab/list/${this.player$.getValue().id}/${this.collab.id}/read`, 'post') );
                this.unread = 0;
                this.buildDiff();
            } catch (e) {
                this.pSvc.notification$.next(e);
            }
        }
    }

    async updateUser(user: RcrtUser, update: any) {
        this.pSvc.blocking$.next(true);
        try {
            Object.assign(user, update);
            console.log('wth',user.last_check);
            await this.fSvc.setDoc(user);
            this.user$.next(user);
        } catch (e) {
            this.pSvc.notification$.next(e?.error||e);
        }
        this.pSvc.blocking$.next(false);
    }

    getLastUrl(user: RcrtUser) {
        let d: any = this.lSvc.globalState['nav'] || {};
        let id: string = user?.collab || user?.id;
        let url: string =  d[id]||'';
        return url;
    }

    setLastUrl(user: RcrtUser,url:string) {
        let d: any = this.lSvc.globalState['nav'] || {};
        d[user?.collab || user?.id] = url;
        this.lSvc.saveGState('nav',d);
    }
}
