import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Page} from '@nxt/model-core';
import {AsyncPipe, NgFor, NgIf} from '@angular/common';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {RcrtStatsService} from '../../_services/rcrt-stats.service';
import {ERcrtActions} from '@nxt/model-rcrt';
import {ClientService} from '@library/shared/_services/client.service';
import {take} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'rcrt-footer',
    standalone: true,
    imports: [
        NgIf, NgFor, RouterModule, AsyncPipe
    ],
    template: `
        <footer class="bg-slate-50 pb-20">
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div class="flex w-full border-t border-slate-400/10 py-10 justify-between">
                    <div class="flex flex-col">
                        <a (click)="refer()" class="text-lg text-dark cursor-pointer mb-3" [innerHTML]="cta_text"></a>
                        <div class="text-sm text-slate-500 mt-3">
                            {{ section.copyright_text }}
                        </div>
                        <div class="mt-10 text-sm text-accent flex flex-col space-y-1">
                            <a *ngFor="let item of section.links"
                               class="underline hover:no-underline" 
                               [routerLink]="item.routerLink">
                                {{ item.label }}
                            </a>
                        </div>
                    </div>
                    <a routerLink="/"><img src="/assets/icons/48x48.png" class="w-10"></a>
                </div>
            </div>
        </footer>
    `
})
export class RcrtFooter implements OnChanges {
    @Input() page: Page;
    @Input() section: any = {
        cta_text: 'Organize & ignite your recruiting journey. <span class="underline hover:no-underline">Click here</span>.',
        copyright_text: `Copyright © ${new Date().getFullYear()} Recruit Messenger. All rights reserved.`,
        links: [
            {
                label: 'Support/Contact Us',
                routerLink: '/support'
            },
            {
                label: 'Privacy Policy',
                routerLink: '/privacy'
            },
            {
                label: 'Staff Change Monitor',
                routerLink: '/public/coaching_changes'
            }
        ]
    }
    cta_text: any;

    constructor(
        private sSvc: RcrtStatsService,
        private cSvc: ClientService,
        private router: Router,
        private aR: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) {
        this.cta_text = this.sanitizer.bypassSecurityTrustHtml(this.section?.cta_text);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.section) {

        }
    }

    async refer() {
        if (this.cSvc.u$.getValue()) {
            let params: any = await this.aR.params.pipe(take(1)).toPromise();
            this.sSvc.track(this.cSvc.u$.getValue(), ERcrtActions.INTERNAL_LINK_CLICK, params);
        }
        return this.router.navigateByUrl(`/`);
    }
}
