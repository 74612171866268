// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    type: 'dev',
    appName: 'Recruit Messenger',
    nxtProjectId: 'nxtpart-dev',
    support: 'support@recruitmessenger.com',
    fnUrl: 'https://us-central1-rcrt-dev.cloudfunctions.net/api',
    url: 'https://dev.recruitmessenger.com',
    firebase: {
        apiKey: 'AIzaSyDQBjjgMj8TaCdoN6bo_duSjKirvdeN7nQ',
        authDomain: 'dev.recruitmessenger.com',
        projectId: 'rcrt-dev',
        storageBucket: 'rcrt-dev.appspot.com',
        messagingSenderId: '949433426487',
        appId: '1:949433426487:web:75b76a2aed0436704f95b2'
    },
    storageUrl: '',
    push_public_key: '',
    default_client: {
        id: 'z2d036v742kzk1iedplupu',
        name_key: 'RCRT',
        phone: '',
        theme: {
            dark: '#262626',
            light: '#d8c690',
            accent: '#be9e44'
        },
        config: {
            dba: 'Recruit Messenger'
        }
    },
    algolia: {
        appId: 'R4QRFG8HNF',
        searchKey: '329314321b79d35da39f0c7e45ddcaf3'
    },
    stripe: 'pk_test_51M4Y6oHe8jPzOwgUzfYFsZxIkxqt4POav2FA85cF8PpqzJNEawy4NZlKg1YX5EWMCOFGFIp1CIEMbNh6KQN7B0Kz00v2kgC9JS',
    paypal: {account: '', client_id: ''},
    login: {
        microsoft: true,
        google: true
    },
    pageServiceSiteId:''
};
