import {Component, HostListener, ViewChild, ViewContainerRef} from '@angular/core';
import {ThemeService} from '@library/shared/_services/theme.service';
import {ClientService} from '@library/shared/_services/client.service';
import {IModalOptions, PageService} from '@library/shared/_services/page.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ModalDialog} from '@library/shared/modal/modal.dialog';
import {take, takeUntil} from 'rxjs/operators';
import {AlertDialog} from '@library/shared/alert/alert.dialog';
import {RootConsumerAppComponent} from '@library/shared/_inherited/root-consumer-app.component';
import {SwUpdate} from '@angular/service-worker';
import {Router} from '@angular/router';
import {round} from '@nxt/model-core';
import {RcrtDataService} from './_services/rcrt-data.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-root',
    template: `
        <notification-dialog></notification-dialog>
        <progress-component icon="/assets/icons/48x48.png" 
                            [loading$]="pSvc.loading$"
                            [blocking$]="pSvc.blocking$"
        ></progress-component>
        <rcrt-header [player]="rdSvc.player$|async" [user]="rdSvc.user$|async"></rcrt-header>
        <div class="w-full min-h-full bg-gray-300 flex">
            <div class="mx-auto max-w-4xl bg-white w-full min-h-screen">
                <router-outlet></router-outlet>
            </div>
        </div>
        <rcrt-footer></rcrt-footer>
        <ng-container #modalContainer></ng-container>
  `
})
export class AppComponent extends RootConsumerAppComponent {
    @ViewChild('modalContainer', {static: true, read: ViewContainerRef}) modalContainer: ViewContainerRef;
    @HostListener('window:scroll', ["$event"]) onListenerTriggered(event: any) {
        let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
        let max = document.documentElement.scrollHeight;
        let p: number = round(pos / max, 2);
        if (!((p * 100) % 5) && p > .6) {
            this.pSvc.scroll$.next(p);
        }
    }

    constructor(
        public pSvc: PageService,
        private sanitizer: DomSanitizer,
        private swUpdate: SwUpdate,
        tSvc: ThemeService,
        router: Router,
        public cSvc: ClientService,
        public rdSvc: RcrtDataService
    ) {

        super(pSvc, tSvc, router);
        tSvc.setTheme(cSvc.client$.getValue()?.theme)

        this.pSvc.modal$.subscribe(
            (opts: IModalOptions) => {
                setTimeout(() => {
                    if (opts) {
                        let ref: any = this.modalContainer.createComponent<any>(ModalDialog);
                        if (ref.instance?.init) {
                            ref.instance.init(opts);
                        }
                        if (!ref.instance.onClose) {
                            console.warn('Component is missing an onClose event.');
                        }
                        ref.instance.onClose?.pipe(take(1))
                            .subscribe(e => {
                                // Fake an esc click
                                this.pSvc.clickEsc$.next(true);
                            });
                    }
                });
            }
        );

        this.pSvc.clickEsc$
            .subscribe(
                r => {
                    if (r) {
                        if (this.modalContainer.length) {
                            this.modalContainer.remove(this.modalContainer.length - 1);
                        }
                    }
                }
            );

        this.pSvc.alert$
            .subscribe(
                (e: any) => {
                    if (e) {
                        let alert: any = {};
                        if (e.title || e.buttons || e.options || e.error?.title) {
                            alert = e.error?.title ? e.error : e;
                        } else if (e.error) {
                            let alert: any = {};
                            ['title', 'message', 'body', 'details'].forEach(p => {
                                alert[p] = (e.error[p] instanceof String && e.error[p].toString() !== 'undefined') ? e.error[p].toString() : '';
                            });
                            ['body', 'details'].forEach(prop => {
                                if (alert[prop]) {
                                    alert.message += ` ${alert[prop]}`;
                                }
                            });
                            ['name'].forEach(prop => {
                                if (alert[prop]) {
                                    alert.title += ` ${alert[prop]}`;
                                }
                            });
                            // console.warn('AFTER', alert);
                            alert = alert;
                            alert.title = alert.title || 'An Error Occurred';
                            alert.message = alert.message || 'Please try again. If the problem persists please contact support.';
                        }
                        if (!alert.title && e.statusText) {
                            alert.title = e.statusText;
                            alert.message = e.message || '';
                        }

                        this.pSvc.modal$.next({
                            component: AlertDialog,
                            buttons: alert.buttons,
                            label: alert.title,
                            onLoaded: (comp: AlertDialog) => {
                                comp.alert = alert;
                                if (e.html) {
                                    comp.html = this.sanitizer.bypassSecurityTrustHtml(e.html);
                                }
                                console.warn(e, alert);
                                this.pSvc.loading$.next(false);;
                                this.pSvc.blocking$.next(false);
                            }
                        });
                    }
                }
            );

        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates.subscribe((u) => {
                if  (u?.type === 'VERSION_DETECTED' || u?.type === 'VERSION_INSTALLATION_FAILED') {
                    this.pSvc.notification$.next({
                        title: 'New Version Detected!',
                        message: 'Click Reload to make sure you have the latest version! Not doing so could result in unpredictable app behavior.',
                        buttons: [
                            {
                                label: 'Reload',
                                click: () => {
                                    window.location.reload();
                                }
                            }
                        ]
                    })
                }
            });
        }

        let sub: Subscription;
        this.rdSvc.user$
            .subscribe(u => {
                sub?.unsubscribe();
                if (u?.id) {
                    sub = this.pSvc.state$
                        .subscribe((url) => {
                            if (url.match(/\/account/)) {
                                this.rdSvc.setLastUrl(u,url);
                            }
                        });
                }
            });

    }


}
