import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {ClientService} from '@library/shared/_services/client.service';
import {environment} from '@env/environment';
import {User as RcrtUser} from '@nxt/model-rcrt';
import {RcrtDataService} from './rcrt-data.service';
import {ConsumerFireService} from '@library/consumer/_services/consumer.fire.service';
import {ConsumerAccountService} from '@library/consumer/_services/consumer.account.service';
import {user as authUser} from '@angular/fire/auth';

@Injectable()
export class RcrtClientGuard {
    constructor(
        private cSvc: ClientService,
        private rdSvc: RcrtDataService,
        private fSvc: ConsumerFireService,
        private aSvc: ConsumerAccountService
    ) {

  }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return new Promise((resolve) => {
            this.cSvc.c$.next(environment.default_client);
            authUser(this.aSvc.auth)
                .subscribe(
                async (u: any) => {
                    if (!u) {
                        await this.aSvc.signInAnon();
                    } else {
                        let user: RcrtUser = u?.uid ? new RcrtUser(await this.fSvc.getDoc(`users/${u.uid}`).toPromise()) : null;
                        if (user?._exists) {
                            let token: any = await u.getIdTokenResult();
                            this.rdSvc.roles$.next(token?.claims?.roles || {});
                            this.rdSvc.user$.next(user);
                        } else {
                            this.rdSvc.roles$.next({});
                            this.rdSvc.user$.next(null);
                        }
                        resolve(true);
                    }
                },
                async (err: any) => {
                    console.warn(err);
                    resolve(false);
                }
            );

        });
    }

}
